
/*

Styles

*/

import './App.scss';

import React, {useState, useEffect, useRef} from 'react';
import {Loader} from './js/components/Loader.js';
import moment from 'moment';

import EmojiConverter from 'neato-emoji-converter';

import ReactAnime from 'react-animejs'

const {Anime} = ReactAnime;

const emoji = new EmojiConverter();

function ReplaceEmojis({text}){

  
  return <span>{emoji.replaceShortcodes(text)}</span>

}


function groupMessages(messages){

    let group = -1;
    let curUser = -1;

    let bubbles = [];

    messages.forEach(m=>{

        let d = new Date(m.raw.ts * 1000);
        let timeKey = d.getDay() + d.getHours() + d.getMinutes();

        // Group consecutive messages from the same user in the same minute into a bubble
        let bubbleId = m.raw.user + timeKey;

        if(bubbleId !== curUser){
          group++;
          curUser = bubbleId;

          bubbles[group] = [];
        }

        bubbles[group].push(m);

    })

    return bubbles;

}

function getLatestImages(messages){

  
    let images = [];

    messages.forEach(m=>{

        if(m.files && m.files.mimetype.match('image')){
          images.push(m.files.url);
        }

    })

    return images.reverse().slice(0,10);

}

function DisplayDate({ts}){
  var d = moment(ts * 1000);
  //console.log(d);
  return <div>{d.format('DD/MM HH.mm')}</div>
}

function AnimateImages({images,getNewImages}){

  let [imagesToShow,setImagesToShow] = useState(images);

  return (
    <div>

        {imagesToShow.map((i,ix)=>(

          <Anime
          key={ix}
          initial={[
            {
              targets: `.App-image--${ix}`,
              translateY: -window.innerHeight * 2,
              easing: "linear",
              duration : 15000 + (Math.random() * 30000),
              delay : 8000 * ix,
              //loop:true,
              complete : (anim) => {
                //alert('done');
               
                if(ix === (images.length - 1)){

                    let newImages = getNewImages();
                    setImagesToShow(newImages);

                }

                anim.restart();
              }
            }
          ]}
        >
          <div className={`App-image App-image--${ix}`}>
           <img alt={`Media animation`} src={i}  />
          </div>
        </Anime>

          ))}
       
     
    </div>
  );


}

function Replies({replies}){

  return (

    <div className="App-message-replies">
      {
        replies.map((m,ix) => {

          if(!ix) return null;

          return <div key={ix}> {`>>>`} <ReplaceEmojis text={m.text} /></div>
        })
      }
    </div>
  )


}

function App() {

  const [cursor,setCursor] = useState(moment().format('YYYYMMDDhhmm'));
  const [messages,setMessages] = useState([]);

  let messagesRef = useRef(messages);

  let timer = useRef(0);
  let cursorRef = useRef(cursor);



  useEffect(()=>{

      timer.current = setInterval(()=>{

      let minuteTimestamp = moment().format('YYYYMMDDhhmm');
      if(minuteTimestamp !== cursorRef.current){
        cursorRef.current = minuteTimestamp;
        setCursor(minuteTimestamp);
      }

      },20000)

      return () => {

        clearInterval(timer.current);
        timer.current = 0;

      }

  },[]);


  return (
    <div className="App">

    <div className="App-head">
      <h1>The Great Escape</h1>
      <h2>A Story of Liberation</h2>
    </div>

   
    <div className="App-messages">
      <Loader key={cursor}  manifest={[
        {
          name:'messages',
          src:`https://proxy.olson.work/?p=https://here-great-escape.netlify.app/.netlify/functions/data?state=${cursor}`,type:'json'
        }
        ]} 
        onLoad={({messages})=>{
            messagesRef.current = messages;
            setMessages(messages);
        }}
        progress={()=><div />}>
        {
          ({messages}) => {

              

              return <div></div>

          }
        }
        </Loader>

        {(()=>{

          if(messages.length){

            return (
            <div>
              {groupMessages(messages).reverse().map(
                bubble => {
                  if(bubble[0].raw.subtype) return null;
                  return (
                    <div className="App-message">
                    <div className="App-message-meta">
                      <div><DisplayDate ts={bubble[0].raw.ts} /></div>
                    </div>
                    <div className="App-message-bubble">
                      {/*console.log(bubble[0])*/}

                      {bubble.map((m,ix)=>{


                        return <div>{(ix === 0) && <span className="App-message-author">{bubble[0].user.user.real_name && bubble[0].user.user.real_name.split(' ')[0]}</span>}<ReplaceEmojis text={m.text}/> {m.reactions && <div>{m.reactions}</div>} {m.files && m.files.mimetype.match('image') && <div><img alt={`Media from conversation`} src={`${m.files.url}`} /></div>}{m.replies && <Replies replies={m.replies.messages} />}</div>

                      })}
                    </div></div>
                  )

                }
              )}

              <div className="App-images">
              <AnimateImages images={getLatestImages(messages)} getNewImages={()=>{
                return getLatestImages(messagesRef.current);
              }}/>
              </div>

            </div>
            )
        }})()}

      </div>
      </div>
  )
}



export default App;
