import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from "react-router-dom";
import ReactBreakpoints from 'react-breakpoints';

import App from './App';

const breakpoints = {
  mobile: 650,
  tablet: 745,
  desktop: 1024,
  desktopWide: 1400,
  desktopMax: 1920
}

ReactDOM.render(
  <React.StrictMode>
  <BrowserRouter>
  <ReactBreakpoints
    breakpoints={breakpoints}
    debounceResize={true}
    defaultBreakpoint={breakpoints.mobile}
  >
    <App />
  </ReactBreakpoints>
  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
