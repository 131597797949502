import React, { Component } from 'react';
import preloader  from 'preloader';



class Loader extends Component {

  start=0;

  constructor(props) {
    super(props);
    this.state = {loaded:0,progress:0,data:{}};
  }

  hasLocalStorage = () => {
    
  var testKey = 'test', storage = window.localStorage;
  try {
    storage.setItem(testKey, '1');
    storage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }

}

  componentDidMount() {

      const _this = this;

    
 

    _this.start = new Date().getTime();

    let loader = preloader({
      xhrImages: false
    });

    let load = 0;
    let assetsLoaded = [];
    let contentLoaded = [];

    let loaded = 0;

    loader.on('progress',function(progress,filename) {

        if(assetsLoaded.indexOf(filename) === -1){
          assetsLoaded.push(filename);
          loaded++;
          progress = loaded / load;

         _this.setState({progress:progress});
       }
    });

    loader.on('complete',function() {

      _this.setState({progress:1});
      assetsLoaded.forEach((i) => {

          let src = (i.src) ? i.src : i;
          //let label = (i.name) ? i.name : i;

          let content = loader.get(src);

          contentLoaded[src] = content;

      });

      let hold = _this.props.hold || 50;
      setTimeout(complete,hold);


    });

    _this.props.manifest.forEach((i) => {

        
        let src = (i.src) ? i.src : i;
        //let label = (i.name) ? i.name : i;
        
        //console.log('LOAD',src);
        // Add a key for the final content set
        contentLoaded[src] = 0;

        let cached = 0;

        if(i.hasOwnProperty('cache') && _this.hasLocalStorage()){

            
            let storage = window.localStorage;
            cached = storage.getItem(src);

            if(cached){
              contentLoaded[src] = JSON.parse(cached);
            }

        }

        if(!cached){

        load++;

        _this.addManifestItem(i,loader);

       }
        
  

    });


    const complete = () => {

   
      let store = {};

      _this.props.manifest.forEach((i) => {

        let src = (i.src) ? i.src : i;
        let label = (i.name) ? i.name : i;

        let content = contentLoaded[src];
        if(!content) content = {};
        let toSave = (i.subset && content.hasOwnProperty(i.subset)) ? content[i.subset] : content;

        if(i.hasOwnProperty('cache') && _this.hasLocalStorage()){

            
            let storage = window.localStorage;
            storage.setItem(src,JSON.stringify(content));

        }else{

        }



        

        //console.log('save',label,toSave,contentLoaded);

        if(i.hasOwnProperty('transformer')){

            toSave = i.transformer(toSave,{...store});

        }

        store[label] = toSave;


    });
      

      let timeNow = new Date().getTime();

      let diff = timeNow - _this.start;

      let wait = _this.props.wait || 0;

      let timeout = wait - diff;

      if(timeout < 0) timeout = 0;
      
      setTimeout(function(){

        _this.setState({progress:1,loaded:1,data:store});
        if(_this.props.onLoad) _this.props.onLoad(store);

      },timeout);


    }
    // End complete()

    if(_this.props.manifest.length && load){
      loader.load();
    }else{
      complete();
    }

    


  }

  addManifestItem = (i,loader,cachebuster='') => {

      if(i.hasOwnProperty('type')){

        switch(i.type){

          case 'json':
            loader.addJSON(i.src+cachebuster);
            break;
          default:
            loader.add(i.src+cachebuster);

        }

      }else{
        loader.add(i.src);
      }

  }
  

  componentWillUnmount() {

  }



  /*
    
    RENDER

  */

  render() {

    //console.log('LOADER',this.state);
   
    if(this.state.loaded){
      
      return this.props.children(this.state.data);
    }

    if(this.props.progress){

        return this.props.progress({data:this.state.data,progress:Math.round(this.state.progress * 100)});

    }

    return (
      <div className="Loader">
          {Math.round(this.state.progress * 100)}%
      </div>
    );

  }
}



export {Loader};
